import * as amplitude from '@amplitude/analytics-browser';
import { SessionI } from '@/types/cyclone/requests';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const AMPLITUDE_API_KEY = '9b9866917c3165f29b0383a54679ca7';
const AMPLITUDE_ONBOARDING_API_KEY = '945d9ea3de50bf1411000163cbe2f313';

let amplitudeOnboardingInstance: ReturnType<typeof amplitude.createInstance> | null = null;

declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
  }
}

export interface CustomProps {
  screen: Screen;
  [propName: string]: unknown;
}

const getUTMParameters = () => {
  if (typeof window === 'undefined') return {}; // Ensure this runs in the browser

  const searchParams = new URLSearchParams(window.location.search);
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const params: any = {};

  utmParams.forEach((param) => {
    const value = searchParams.get(param);
    if (value) params[param] = value;
  });

  return params;
};

export const initAmplitude = (session: SessionI | null) => {
  const isOnboarding = session?.vendor?.is_onboarding_finished === false;
  const isProd = import.meta.env.VITE_NODE_ENV === 'production';

  // Initialize main instance (without session replay)
  amplitude.init(AMPLITUDE_API_KEY, {
    trackingOptions: {
      ipAddress: true,
      language: true,
      platform: true
    },
    logLevel: amplitude.Types.LogLevel.Warn
  });

  // Initialize onboarding instance with session replay if conditions are met
  if (isProd && isOnboarding && session) {
    amplitudeOnboardingInstance = amplitude.createInstance();

    // Configure session replay only for onboarding instance
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1.0
    });

    // Add plugin before initializing the instance
    amplitudeOnboardingInstance.add(sessionReplayTracking);

    amplitudeOnboardingInstance.init(AMPLITUDE_ONBOARDING_API_KEY, {
      trackingOptions: {
        ipAddress: true,
        language: true,
        platform: true
      },
      logLevel: amplitude.Types.LogLevel.Warn,
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true
      }
    });
  }
};

export const identifyUser = (session: SessionI): void => {
  amplitude.setUserId(session.id.toString());

  let paymentMethods = '';
  if (session.vendor?.payment_gateways && session.vendor.payment_gateways.length > 0) {
    paymentMethods = session.vendor.payment_gateways.join(', ');
  }
  if (session.vendor?.is_payment_bank_transfer_enabled) {
    paymentMethods += (paymentMethods ? ', ' : '') + 'Bank Transfer';
  }
  if (session.vendor?.is_payment_cash_enabled) {
    paymentMethods += (paymentMethods ? ', ' : '') + 'Cash';
  }

  paymentMethods = paymentMethods || 'No payment methods available';

  const identify = new amplitude.Identify();
  identify.set('first_name', session.first_name || '');
  identify.set('last_name', session.last_name || '');
  identify.set('username', session.vendor?.username || '');
  identify.set('email', session.email || '');
  identify.set('name', session.first_name + ' ' + session.last_name);
  identify.set('phone', session.phone || '');
  identify.set('role', session.role || '');
  identify.set('plan', session.vendor?.plan_name || '');
  identify.set('price_monthly', session.active_plan ? session.active_plan.price_monthly : '');
  identify.set('log_in', session.connection_type);
  identify.set('payment_methods', paymentMethods);
  identify.set('first_service_date_creation', session.first_service_date_creation || '');
  identify.set('first_confirmed_booking_date', session.first_confirmed_booking_date || '');
  identify.set('first_basic_storefront_date', session.first_basic_storefront_date || '');
  identify.set('total_confirmed_bookings', session.total_confirmed_bookings || 0);

  amplitude.identify(identify);

  // Identify the user in onboarding instance as well if it's active
  if (amplitudeOnboardingInstance) {
    amplitudeOnboardingInstance.setUserId(session.id.toString());
    amplitudeOnboardingInstance.identify(identify);
  }
};

export const trackGenericEvent = (name: string, customProps?: Partial<CustomProps>): void => {
  const utmParams = getUTMParameters();

  if (import.meta.env.VITE_NODE_ENV == 'production') {
    amplitude.track(name, {
      ...customProps,
      ...utmParams
    });

    // Track event in onboarding instance as well if it's active
    if (amplitudeOnboardingInstance) {
      amplitudeOnboardingInstance.track(name, {
        ...customProps,
        ...utmParams
      });
    }
  } else {
    console.log('Event:', name, customProps);
  }
};

export const trackGenericPage = (name: string, customProps?: Partial<CustomProps>): void => {
  const utmParams = getUTMParameters();

  amplitude.track(`Viewed ${name} Page`, {
    ...customProps,
    ...utmParams
  });

  // Track page views in onboarding instance as well if it's active
  if (amplitudeOnboardingInstance) {
    amplitudeOnboardingInstance.track(`Viewed ${name} Page`, {
      ...customProps,
      ...utmParams
    });
  }
};

export const sendToGTM = (event: string): void => {
  if (import.meta.env.VITE_NODE_ENV == 'production')
    window.dataLayer.push({
      event
    });
  else return;
};
